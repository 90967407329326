<template>
  <div>
    <div class='onboarder__wrapper' v-if='isShown'>
      <div class='onboarder__modal'>
        <div class='onboarder__modal__header'>
          <h1>Learn Hudu</h1>
          <a href='#' class='close' @click.prevent.stop='closeModal'><i class='fas fa-times'></i></a>
        </div>
        <div class='onboarder__steps'>
          <a href='#' :class='{"selected": step == 1}' @click.prevent.stop='step = 1'>Most Important</a>
          <a href='#' :class='{"selected": step == 2}' @click.prevent.stop='step = 2'>Other Topics</a>
        </div>
        <div class='onboarder__modal__content'>
          <concepts v-if='step == 1'></concepts>
          <conclusion v-if='step == 2'></conclusion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Intro from "../components/onboarder/Intro.vue";
import Concepts from "../components/onboarder/Concepts.vue";
import Conclusion from "../components/onboarder/Conclusion.vue";
import { mapGetters } from 'vuex'

export default {
  props: ["autoshow", "close_url"],
  components: {
    Intro,
    Concepts,
    Conclusion
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("globals", {
      'isShown': 'showOnboarder'
    })
  },
  mounted () {
    if (this.autoshow) {
      this.toggle(7)
    }
  },
  methods: {
    toggle(newHeader) {
      this.$store.commit('globals/changeSub', newHeader)
    },
    closeModal () {
      var that = this;
      that.$api.post(that.close_url)
      .then(function (response) {
        that.toggle(0)
      })
    }
  },
  data: function () {
    return {
      step: 1,
    }
  }
}
</script>
